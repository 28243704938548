<template>
  <div>
  <CCard>
     <CCardHeader>
            <strong>Safety Inspections</strong>
          </CCardHeader>
  <CCardBody>
    <!-- <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewLocation">Add New Crane</button>
    </div> -->

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
      >
        <template #weather-filter>
          <select
            class="form-control form-control-sm"
            @input="
              $refs.vuetable.columnFilterEvent(
                'weather',
                $event.target.value,
                'input'
              )
            "
          >
            <option value selected="selected">Any</option>
            <option value="1">Weather Fine</option>
            <option value="2">Weather Rain</option>
             <option value="3">Weather Cloudy</option>
          </select>
        </template>
         <template #checkType-filter>
          <select
            class="form-control form-control-sm"
            @input="
              $refs.vuetable.columnFilterEvent(
                'checkType',
                $event.target.value,
                'input'
              )
            "
          >
            <option value selected="selected">Any</option>
            <option value="1">Permit Work Check</option>
            <option value="2">Risk Assessment Check</option>
             <option value="3">Daily Toolbox Check</option>
             <option value="4">Safety Barricade Check</option>
             <option value="5">Equipment Check</option>
             <option value="6">Personal Safety Check</option>
             <option value="7">House Keeping Check</option>
             <option value="8">Hot Works Check</option>
             <option value="9">Scaf Folding Check</option>
             <option value="10">Work In Confined Check</option>
             <option value="11">Work At Height Check</option>
              <option value="12">Excavation Works Check</option>
             <option value="13">>Lifting Works Check</option>
             
          </select>
        </template>
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

       <template #checkType="{item}">
        <td class='font-weight-bold'  v-show="item.checkType==1">Permit Work Check</td>      
        <td class='font-weight-bold' v-show="item.checkType==2">Risk Assessment Check</td>
        <td class='font-weight-bold' v-show="item.checkType==3">Daily Toolbox Check </td>
        <td class='font-weight-bold' v-show="item.checkType==4">Safety Barricade Check</td>
        <td class='font-weight-bold' v-show="item.checkType==5">Equipment Check </td>
         <td class='font-weight-bold' v-show="item.checkType==6">Personal Safety Check</td>
         <td class='font-weight-bold' v-show="item.checkType==7">House Keeping Check</td>
          <td class='font-weight-bold' v-show="item.checkType==8">Hot Works Check</td>
           <td class='font-weight-bold' v-show="item.checkType==9">Scaf Folding Check</td>
           <td class='font-weight-bold' v-show="item.checkType==10">Work In Confined Check</td>
            <td class='font-weight-bold' v-show="item.checkType==11">Work At Height Check</td>
             <td class='font-weight-bold' v-show="item.checkType==12">Excavation Works Check</td>
              <td class='font-weight-bold' v-show="item.checkType==13">Lifting Works Check</td>

      </template> 

       <template #weather="{item}">
        <td class='font-weight-bold'  v-show="item.weather==1">Weather Fine</td>      
        <td class='font-weight-bold' v-show="item.weather==2">Weather Rain</td>
        <td class='font-weight-bold' v-show="item.weather==3">Weather Cloudy</td>
       

      </template> 
       <template #nonCompliances="{item}">
        <td class='font-weight-bold'
          v-if="item.nonCompliances"
        >{{ item.nonCompliances }}</td>
        <td v-else>--</td>
      </template>
      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
       <template #inspectionTime="{item}">
        <td class='font-weight-bold'
          v-if="item.inspectionTime"
        >{{ item.inspectionTime |dateParse('hh:mm A')| dateFormat("hh:mm A ") }}</td>
        <td v-else>--</td>
      </template>
      <template #inspectionDate="{item}">
        <td class='font-weight-bold'
          v-if="item.inspectionDate"
        >{{ item.inspectionDate |dateParse('YYYY.MM.DD')| dateFormat("MMM D, YYYY ") }}</td>
        <td v-else>--</td>
      </template>
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                   <CDropdownItem :to="{name: 'Inspection', params: { inspectionId: item. inspectionId, mode: 'view'}}">Inspection</CDropdownItem> 
             
                </CDropdown> 

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>

 </CCard>
  <CCard>
   <CCardHeader>
            <strong>Over All Score</strong>
          </CCardHeader>
        
    <CCardBody>
   <CDataTable :items="overallScore"  
    :fields="score"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
      >
        <template #weather-filter>
          <select
            class="form-control form-control-sm"
            @input="
              $refs.vuetable.columnFilterEvent(
                'weather',
                $event.target.value,
                'input'
              )
            "
          >
            <option value selected="selected">Any</option>
            <option value="1">Weather Fine</option>
            <option value="2">Weather Rain</option>
             <option value="3">Weather Cloudy</option>
          </select>
        </template>
       
       <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>
 <template #weather="{item}">
        <td class='font-weight-bold'  v-show="item.weather==1">Weather Fine</td>      
        <td class='font-weight-bold' v-show="item.weather==2">Weather Rain</td>
        <td class='font-weight-bold' v-show="item.weather==3">Weather Cloudy</td>
      </template> 
    <template #inspectionDate="{item}">
        <td class='font-weight-bold'
          v-if="item.inspectionDate"
        >{{ item.inspectionDate |dateParse('YYYY.MM.DD')| dateFormat("MMM D, YYYY ") }}</td>
        <td v-else>--</td>
      </template>

       <!-- <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                   <CDropdownItem :to="{name: 'Job Tasks', params: { craneId: item. craneId, mode: 'view'}}">Job Tasks</CDropdownItem> 
           
                </CDropdown> 

        </td>
      </template> -->
      </CDataTable>
    </CCardBody>
  </CCard> 

 <CCard>
    <CCardHeader>
            <strong>Work Site Inspection</strong>
          </CCardHeader>
        

          <CCardBody>
          <CDataTable :items="worksite"  
    :fields="field"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
      >
           <template #weather-filter>
          <select
            class="form-control form-control-sm"
            @input="
              $refs.vuetable.columnFilterEvent(
                'weather',
                $event.target.value,
                'input'
              )
            "
          >
            <option value selected="selected">Any</option>
            <option value="1">Weather Fine</option>
            <option value="2">Weather Rain</option>
             <option value="3">Weather Cloudy</option>
          </select>
        </template>
       
       <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>
 <template #weather="{item}">
        <td class='font-weight-bold'  v-show="item.weather==1">Weather Fine</td>      
        <td class='font-weight-bold' v-show="item.weather==2">Weather Rain</td>
        <td class='font-weight-bold' v-show="item.weather==3">Weather Cloudy</td>
      </template> 
    <template #inspectionDate="{item}">
        <td class='font-weight-bold'
          v-if="item.inspectionDate"
        >{{ item.inspectionDate |dateParse('YYYY.MM.DD')| dateFormat("MMM D, YYYY ") }}</td>
        <td v-else>--</td>
      </template>

       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                   <CDropdownItem :to="{name: 'Labours', params: { progressId: item. progressId}}">Labours</CDropdownItem> 
                     <CDropdownItem :to="{name: 'Plants', params: { progressId: item. progressId}}">Plants</CDropdownItem> 
           
                </CDropdown> 

        </td>
      </template>
      </CDataTable>
            
          </CCardBody>
          
        
          <CCardFooter align="right">
        
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
  // { key: "checkTypeValue",label: "Check Type Value", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "inspectionNo",label: "Inspection No", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  //  { key: "checkType",label: "CheckType", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    // { key: "nonCompliances",label: "Non Compliances", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    
    { key: "weather",label: "Weather", _classes: 'font-weight-bold',  _style: "min-width:200px" },    
   { key: "inspectionDate",label: "Inspection Date", _classes: 'font-weight-bold',  _style: "min-width:200px" },
 { key: "inspectionTime",label: "Inspection Time", _classes: 'font-weight-bold',  _style: "min-width:200px" },
// { key: "createdTime",label: "Created Date & Time", _classes: 'font-weight-bold', _style: "min-width:200px" },
  
  { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];



const score = [
  'index',
  { key: "hsseManagement",label: "Hsse Management", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  
  { key: "progressOfWork",label: "Progress Of Work", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  { key: "qualityIndicator",label: "Quality Indicator", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  // { key: "scoreId",label: "Score Id", _classes: 'font-weight-bold',  _style: "min-width:200px" },
   { key: "siteInstructions",label: "Site Instructions", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  { key: "sitePlanning",label: "Site Planning", _classes: 'font-weight-bold',  _style: "min-width:200px" },
   { key: "weather",label: "Weather", _classes: 'font-weight-bold',  _style: "min-width:200px" },
   { key: "inspectionDate",label: "Inspection Date", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  
 
];

const field = [
  'index',
 
  { key: "materialDelivery",label: "Material Delivery", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  { key: "permanentWork",label: "Permanent Work", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  { key: "temporaryWork",label: "Temporary Work", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  { key: "weather",label: "Weather", _classes: 'font-weight-bold',  _style: "min-width:200px" },
   { key: "inspectionDate",label: "Inspection Date", _classes: 'font-weight-bold',  _style: "min-width:200px" },
 { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "Inspections",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      worksite: [],
      overallScore:[],
      
       fields,
        field,
      score,
      details: [],
      collapseDuration: 0,
    
     // jobId: "",
  //     inspection:{
       
  //       overallScore:{
  //  jobId: "",
  //  hsseManagement: "",
  //   inspectionDate: "",
  //   progressId:"",
  //   progressOfWork: "",
  //   qualityIndicator: "",
  //   qualityOfWork: "",
  //   scoreId: "",
  //   siteInstructions: "",
  //   sitePlanning: "",
  //   weather: ""
  //       },
      
  //     workSiteProgress:{
  //   jobId: "",
  //   inspectionDate: "",
    
  //   materialDelivery: "",
  //   permanentWork: "",
  //   progressId: "",
  //   temporaryWork: "",
  //   weather: "",
  //     }
  //     }
    };
  },

  methods: {

    getInspections() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/safety/inspections/"+this.$route.params.jobId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
  
     getWorksiteProgress() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/worksiteProgress/"+this.$route.params.jobId)
        .then((response) => response.json())
        .then((data) => (this.worksite = data));
    },
    getOverallScore() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/OverallScores/"+this.$route.params.jobId)
        .then((response) => response.json())
        .then((data) => (this.overallScore = data));
    },
     cancel() {
  
     this.$router.push({ name: "Civil Maintenances" });
    },
  },
  mounted() {
   this.getInspections();
    this.getOverallScore();
    this.getWorksiteProgress();
  },
};
</script>